import { useMatch } from '@remix-run/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { io, type Socket } from 'socket.io-client';
import { z } from 'zod';
import type {
	ClientToServerEvents,
	ServerToClientEvents,
} from '~/core/socketio/socket.server.ts';
import { useEvent } from '~/utils/use-event.ts';

type TypedSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

type SocketContextType = {
	socket: TypedSocket | null;
	isConnected: boolean;
};

const socketContext = createContext<SocketContextType>({
	socket: null,
	isConnected: false,
});

export function useSocket() {
	return useContext(socketContext);
}

const eventHandlersMap = new Map<string, ((...args: any[]) => any)[]>();

export const useSocketEvent: TypedSocket['on'] = (key, handler) => {
	const { socket } = useSocket();
	const eventHandler = useEvent(handler);

	useEffect(() => {
		let handlers = eventHandlersMap.get(key);
		if (!handlers) {
			handlers = [];
			eventHandlersMap.set(key, handlers);
		}

		if (handlers.length === 0) {
			socket?.on(key, ((...args: any[]) => {
				for (const handler of handlers!) {
					handler(...args);
				}
			}) as any);
		}

		handlers.push(eventHandler);

		return () => {
			const handlers = eventHandlersMap.get(key);
			if (handlers) {
				const index = handlers.findIndex(h => h === eventHandler);
				if (index !== -1) {
					handlers.splice(index, 1);
				}
			}
			if (!handlers || handlers.length === 0) {
				socket?.off(key);
			}
		};
	}, [socket, key, eventHandler]);

	return socket!;
};

const socketError = z.instanceof(Error).and(
	z.object({
		message: z.string().nonempty(),
		data: z.unknown().optional(),
	}),
);

export function SocketProvider({ children }: { children: React.ReactNode }) {
	const [socket, setSocket] = useState<TypedSocket | null>(null);
	const [isConnected, setIsConnected] = useState(false);

	useEffect(() => {
		const socketInstance = io({
			transports: ['websocket'],
			upgrade: false,
			autoConnect: location.pathname.startsWith('/app'),
			path: '/api/socket',
			addTrailingSlash: false,
			withCredentials: true,
		}) as TypedSocket;

		socketInstance.on('connect', () => {
			setIsConnected(true);
		});

		socketInstance.on('disconnect', () => {
			setIsConnected(false);
		});

		socketInstance.on('connect_error', err => {
			const { message } = socketError.parse(err);

			console.error(`Socket connection error: ${message}`);
		});

		setSocket(socketInstance);

		return () => {
			socketInstance.disconnect();
		};
	}, []);

	const value = useMemo(() => ({ socket, isConnected }), [socket, isConnected]);

	return (
		<socketContext.Provider value={value}>
			<SocketAutoconnect />
			{children}
		</socketContext.Provider>
	);
}

function SocketAutoconnect() {
	const { socket, isConnected } = useSocket();
	const shouldConnect = !!useMatch('/app/*');

	useEffect(() => {
		if (shouldConnect && !isConnected) {
			socket?.open();
		} else if (!shouldConnect && isConnected) {
			socket?.close();
		}
	}, [shouldConnect, isConnected, socket]);

	return null;
}
